import {Injectable} from '@angular/core';
import {
  ISidebarLink,
  ILink,
  ISidebarCounter,
  ISideBarMenuItem
} from '../../../models/sidebar';
import {IRolePrivilege} from '../../../models/rolePrivileges';
import { Utils as Util } from '../../../core/functions';
import * as Consts from '../../../libs/app.constants';
import {ConfigService} from '../../../config/config.service';
import {SessionHelper} from '../../../core';
import {forkJoin, merge, Observable, of, zip} from 'rxjs';
import {filter, map, share, startWith} from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';
import {NotificationService as CustomSubService} from '../../../core/customSubService';
import {IMenuNotification} from '../../../models/notification';
import {IRole} from '../../../models/role';
import {PreferenceHelper} from '../../preference/preference.helper';
import {TomService} from '../../../services/tom.service';
import { TradeService } from 'src/app/services/trade.service';
import { ITradesCount, ITradesCountUpdate, OrderCountsType } from 'src/app/models/trade';
import { PreferenceService } from '../../../services/preference.service';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public static DELTA_TIMEOUT = 3000;
  private notifications$: Observable<any>;

  constructor(private _notificationService: NotificationService, private customSubService: CustomSubService, private _preferenceHelper: PreferenceHelper, private sessionHelper: SessionHelper,
              private tomService: TomService, private _tradeService: TradeService, private _preferenceService: PreferenceService, private _splitIOService: SplitIoService) {
    this.notifications$ = _notificationService.notificationUpdated
      .pipe(
        filter(msg => msg !== null),
        share()
      );
  }

  /**
   * Creates a menu item for a category
   * @param menuLink link which is used for the basis of the menu item
   */
  createMenuItem(menuLink: ISidebarLink): ISideBarMenuItem {
    const menu: ISideBarMenuItem = {
      id: menuLink.id,
      type: menuLink.type || 'Route',
      text: menuLink.name,
      icon: null,
      link: menuLink.routeUrl,
      alternateLinks: menuLink.alternateLinks,
      counter: menuLink.counter,
      children: null,
    };
    if (menuLink.childLinks) {
      menuLink.childLinks.filter(item => !item.hidden).forEach((pageItem) => {
        const grandchildrenBarMenu = this.createMenuItem(pageItem);
        grandchildrenBarMenu.parent = menu;
        if (menu.children === null) {
          menu.children = [];
        }
        menu.children.push(grandchildrenBarMenu);
      });
    }
    return menu;
  }

  public createMenuFromLinks(links: ILink[]): ISideBarMenuItem[] {
    const result = [];
    links.forEach((appItem) => {
      const sideBarMenu: ISideBarMenuItem = {
        id: appItem.id,
        type: appItem.externalUrl ? 'External' : 'Route',
        text: appItem.name,
        icon: appItem.imageName,
        link: appItem.externalUrl || appItem.routeUrl,
        ignoreLink: appItem.ignoreLink,
        alternateLinks: appItem.alternateLinks,
        hasOpen: false,
        childActive: false,
        children: null
      };
      if (appItem.childLinks) {
        appItem.childLinks.filter(item => !item.hidden).forEach((catItem) => {
          const categoryMenu = this.createMenuItem(catItem);
          categoryMenu.parent = sideBarMenu;
          if (sideBarMenu.children === null) {
            sideBarMenu.children = [];
          }
          sideBarMenu.children.push(categoryMenu);
        });
      }
      result.push(sideBarMenu);
    });
    return result;
  }

  /**
   * Creates a counter object containing the value from a notification message
   * @param value
   */
  createCounter(value: number): ISidebarCounter {
    return <ISidebarCounter>{total: value};
  }

  /**
   * Creates a counter object containing the value and value delta information from a notification message
   * @param message
   */
  createCounterDelta(message: IMenuNotification): ISidebarCounter {
    const model = <ISidebarCounter>{total: message.total};
    if (message.increment !== undefined) {
      model.delta = {
        isDown: message.increment < 0,
        count: message.increment
      };
      setTimeout(() => model.delta = null, SidebarService.DELTA_TIMEOUT);
    }
    return model;
  }

  /**
   * Generates the full menu used by the sidebar
   */
  public getNavigationLinks(): Observable<ILink[]> {
    //TODO: change this back to a forkJoin() once the non-streaming split query is stable and ready
    return zip([
      this.createOverviewNavigationItems(),
      this.createPortfolioNavigationItems(),
      this.createAccountNavigationItems(),
      this.createTradeToolsNavigationItems(),
      this.createTradesNavigationItems(),
      this.createStrategyNavigationItems(),
      this.createManageUsersNavigationItems(),
      this.createAdminNavigationItems(),
      this.createQueryBuilderNavigationItems(),
      this.createCustomImportsNavigationItems(),
      this.createCommunitiesNavigationItems(),
      this.createOCNavigationItems(),
    ]).pipe(map(linkCategory => {
      // filter out the categories that didn't create any links
      return linkCategory.filter(link => !!link);
    }));
  }

  private createOverviewNavigationItems(): Observable<ILink> {
    return of({
      id: 'overview',
      externalUrl: null,
      type: 'Route',
      name: 'Overview',
      imageName: 'fas fa-fw fa-table',
      routeUrl: '/eclipse/dashboard',
    });
  }

  private createPortfolioNavigationItems(): Observable<ILink> {
    const link = <ILink>{
      id: 'portfolio',
      externalUrl: null,
      name: 'Portfolios',
      type: 'Route',
      imageName: 'fas fa-fw fa-cubes',
      routeUrl: '/eclipse/portfolio/list',
      alternateLinks: ['/eclipse/portfolio'],
    };
    return of(link);
  }

  private createAccountNavigationItems(): Observable<ILink> {
    const astroPrivilege = Util.getPermission(Consts.PRIV_ASTRO)?.canRead;

    const link = <ILink>{
      id: 'account',
      externalUrl: null,
      name: 'Accounts',
      type: 'Route',
      imageName: 'fas fa-fw fa-briefcase',
      routeUrl: '/eclipse/account/list',
      alternateLinks: ['/eclipse/account'],
      parameters: '',
      childLinks: [
        {
          id: 'accountList',
          name: 'All Accounts',
          routeUrl: '/eclipse/account/list',
        }, {
          id: 'accountAstroAlerts',
          name: 'Astro Alerts',
          routeUrl: '/eclipse/account/alerts',
          hidden: !astroPrivilege,
        }
      ]
    };

    // If Account List is the only child link, clear out the link list since clicking the parent "Accounts" menu
    // will navigate to the Account List by default.
    if (link.childLinks?.filter(cl => !cl.hidden).every(cl => cl.id === 'accountList')) {
      link.childLinks = [];
    }

    return of(link);
  }

  private createTradeToolsNavigationItems(): Observable<ILink> {
    return this._splitIOService.flagsEnabled(['mass_liquidation_f211351'])
      .pipe(map((flags: { [key: string]: boolean }) => {
        const tradeToolPrivileges = {
          cashNeedsToolPermission: Util.hasTradeToolRight(Consts.PRIV_CASHNEEDS),
          globalTradesToolPermission: Util.hasTradeToolRight(Consts.PRIV_GLOBALTRADES),
          optionTradesToolPermission: Util.hasTradeToolRight(Consts.PRIV_OPTION_TRADING),
          quickTradeToolPermission: Util.hasTradeToolRight(Consts.PRIV_QUICKTRADES),
          raiseCashToolPermission: Util.hasTradeToolRight(Consts.PRIV_RAISECASH),
          rebalancerToolPermission: Util.hasTradeToolRight(Consts.PRIV_REBALANCER),
          spendCashToolPermission: Util.hasTradeToolRight(Consts.PRIV_SPENDCASH),
          tacticalTradeToolPermission: Util.hasTradeToolRight(Consts.PRIV_TACTICAL),
          taxHarvestingToolPermission: Util.hasTradeToolRight(Consts.PRIV_TAXHARVESTING),
          tradeToTargetToolPermission: Util.hasTradeToolRight(Consts.PRIV_TRADETOTARGET),
          liquidateToolPermission: flags['mass_liquidation_f211351'] && Util.hasTradeToolRight(Consts.PRIV_LIQUIDATE)
        };
        const link: ILink = {
          id: 'tradeTools',
          externalUrl: null,
          name: 'Trade Tools',
          type: 'Route',
          imageName: 'fas fa-fw fa-display-chart-up-circle-dollar',
          routeUrl: '/eclipse/tradetool',
          childLinks: [{
            id: 'tradeToolCashNeeds',
            name: 'Cash Needs',
            routeUrl: '/eclipse/tradetool/cashneed',
            hidden: !tradeToolPrivileges?.cashNeedsToolPermission,
          }, {
            id: 'tradeToolGlobalTrades',
            name: 'Global Trades',
            routeUrl: '/eclipse/tradetool/globaltrades',
            hidden: !tradeToolPrivileges?.globalTradesToolPermission,
          }, {
            id: 'tradeToolOptions',
            name: 'Option Trading',
            routeUrl: '/eclipse/tradetool/option',
            hidden: !tradeToolPrivileges?.optionTradesToolPermission,
          }, {
            id: 'tradeToolRaiseCash',
            name: 'Raise Cash',
            routeUrl: '/eclipse/tradetool/raisecash',
            hidden: !tradeToolPrivileges?.raiseCashToolPermission,
          }, {
            id: 'tradeToolSpendCash',
            name: 'Spend Cash',
            routeUrl: '/eclipse/tradetool/spendcash',
            hidden: !tradeToolPrivileges?.spendCashToolPermission,
          }, {
            id: 'tradeToolRebalancer',
            name: 'Rebalancer',
            routeUrl: '/eclipse/tradetool/rebalancer',
            hidden: !tradeToolPrivileges?.rebalancerToolPermission,
          }, {
            id: 'tradeToolTactical',
            name: 'Tactical',
            routeUrl: '/eclipse/tradetool/tactical',
            hidden: !tradeToolPrivileges?.tacticalTradeToolPermission,
          }, {
            id: 'tradeToolTLH',
            name: 'Tax Harvesting',
            routeUrl: '/eclipse/tradetool/tlh',
            hidden: !tradeToolPrivileges?.taxHarvestingToolPermission,
          }, {
            id: 'tradeToolTradeToTarget',
            name: 'Trade to Target',
            routeUrl: '/eclipse/tradetool/tradetotarget',
            hidden: !tradeToolPrivileges?.tradeToTargetToolPermission,
          }, {
            id: 'tradeToolLiquidate',
            name: Consts.LIQUIDATE_CONSTANTS.TOOL_NAME,
            routeUrl: Consts.LIQUIDATE_CONSTANTS.TOOL_ROUTE,
            hidden: !tradeToolPrivileges?.liquidateToolPermission
          }]
        };
        // If there are any visible trade tools, add the Trade Tools link to the sidebar
        if (link.childLinks.some(tt => !tt.hidden)) {
          return link;
        }
        return null;
      }));
  }

  private createTradesNavigationItems(): Observable<ILink> {
    const isTradeCountsPolled = true;
    const startVal = {
      noOfClosedTrades: 0,
      noOfFixedTrades: 0,
      noOfTradeBlocks: 0,
      openOrdersCount: 0,
      pendingOrdersCount: 0,
      tradeQueueCount: 0,
      tradesFileCount: 0,
      unallocatedBlocks: 0,
      mfBatchesAndAwaitingOrderCount: 0,
      closedMFBatchesCount: 0
    };
    // Observable for initial trade counts.  Start with an initial 0 value so the counter isn't blank at the start.
    const dashboardData$ = this.tomService.getTradesCount().pipe(share(), startWith(startVal));
    const astroPrivilege = Util.getPermission(Consts.PRIV_ASTROOPTIMIZATION)?.canRead;
    const queuePrivilege = Util.getPermission(Consts.PRIV_QUEUE);
    const fixedIncomeCount$ = isTradeCountsPolled ?
      this._tradeService.tradesCountUpdated.pipe(map((tradesCount: ITradesCountUpdate) => tradesCount.noOfFixedTrades)) :
      this.customSubService.fixedOrdersNotify.pipe(startWith(0), map(data => this.createCounter(+data)));

    /**
     * Explanation of counter:
     * The counter for each menu item depends on two different input streams, the initial count and the updated count as trades occur.
     * -The initial count is handled by the dashboardData$ stream and will complete after it has returned a result.
     * -The updated count (long-running subscription) will occur when both are true: user has performed trade action (rebalance, process trades, delete trades), and sidebar is collapsed or trade sub-menu is expanded (as of Azure ticket OAT-99923).
     * These streams are merged together using merge() because we want both observables to impact the same subscribing element and not have
     * to wait for both streams to emit a value before informing the subscriber (which would not be the case if we had used `concat`).
     */
    return of({
      id: 'trades',
      externalUrl: null,
      name: 'Trades',
      type: 'Route',
      imageName: 'fas fa-fw fa-exchange',
      routeUrl: '/eclipse/tradeorder',
      childLinks: [
        {
          id: 'tradesDashboard',
          name: 'Dashboard',
          routeUrl: '/eclipse/tradeorder/dashboard'
        }, {
          id: 'tradesOrders',
          name: 'Orders',
          counter: merge(dashboardData$.pipe(map(v => ({total: v.openOrdersCount}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.OPEN.name, OrderCountsType.OPEN.notificationType)),
          routeUrl: '/eclipse/tradeorder/list',
          alternateLinks: ['/eclipse/tradeorder/list', '/eclipse/tradeorder/awaiting', '/eclipse/tradeorder/fixedincome'],
          childLinks: [{
            id: 'tradesOrdersOpen',
            name: 'Orders',
            routeUrl: '/eclipse/tradeorder/list',
            alternateLinks: ['/eclipse/tradeorder/audit'],
          }, {
            id: 'tradesOrdersFixedIncome',
            name: 'Fixed Income',
            routeUrl: '/eclipse/tradeorder/fixedIncome',
            counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.noOfFixedTrades}))),
              fixedIncomeCount$),
          }]
        }, {
          id: 'tradesBlocks',
          name: 'Blocks',
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.noOfTradeBlocks}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.BLOCKS.name, OrderCountsType.BLOCKS.notificationType)),
          routeUrl: '/eclipse/tradeorder/blocks',
          alternateLinks: ['/eclipse/tradeorder/blocksaudit']
        }, {
          id: 'tradesAllocations',
          name: 'Allocations',
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.unallocatedBlocks}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.ALLOCATIONS.name, OrderCountsType.ALLOCATIONS.notificationType)),
          routeUrl: '/eclipse/tradeorder/allocations'
        }, {
          id: 'tradesFixMFBatches',
          name: 'Fix MF Batches',
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.mfBatchesAndAwaitingOrderCount}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.MF_BATCHES.name, OrderCountsType.MF_BATCHES.notificationType)),
          routeUrl: '/eclipse/tradeorder/mutualfundbatches'
        }, {
          id: 'tradesPending',
          name: 'Pending',
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.pendingOrdersCount}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.PENDING.name, OrderCountsType.PENDING.notificationType)),
          alternateLinks: ['/eclipse/tradeorder/pending', '/eclipse/tradeorder/pendingblocks'],
          routeUrl: '/eclipse/tradeorder/pending',
          childLinks: [{
            id: 'tradesPendingOrders',
            name: 'Orders',
            routeUrl: '/eclipse/tradeorder/pending'
          }, {
            id: 'tradesPendingBlocks',
            name: 'Blocks',
            routeUrl: '/eclipse/tradeorder/pendingblocks'
          }]
        }, {
          id: 'tradesClosed',
          name: 'Closed',
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.noOfClosedTrades}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.CLOSED.name, OrderCountsType.CLOSED.notificationType)),
          alternateLinks: ['/eclipse/tradeorder/closed', '/eclipse/tradeorder/closedblocks'],
          routeUrl: '/eclipse/tradeorder/closed',
          childLinks: [{
            id: 'tradesClosedOrders',
            name: 'Orders',
            routeUrl: '/eclipse/tradeorder/closed'
          }, {
            id: 'tradesClosedBlocks',
            name: 'Blocks',
            routeUrl: '/eclipse/tradeorder/closedblocks'
          }, {
            id: 'tradesClosedMFBatches',
            name: 'Closed MF Batches',
            routeUrl: '/eclipse/tradeorder/closedmutualfundbatches'
          }]
        }, {
          id: 'tradesFiles',
          name: 'Trade Files',
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.tradesFileCount}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.TRADE_FILES.name, OrderCountsType.TRADE_FILES.notificationType)),
          routeUrl: '/eclipse/tradeorder/tradefile'
        }, {
          id: 'tradesInstances',
          name: 'Instances',
          routeUrl: '/eclipse/tradeorder/instances'
        }, {
          id: 'tradesQueue',
          name: 'Queue',
          hidden: !queuePrivilege || !queuePrivilege.canRead,
          counter: merge(dashboardData$.pipe(map((tradesCount: ITradesCount) => ({total: tradesCount.tradeQueueCount}))),
            this.getTradeNavigationChildLink(isTradeCountsPolled, OrderCountsType.QUEUE.name, OrderCountsType.QUEUE.notificationType)),
          routeUrl: '/eclipse/tradeorder/queue',
          childLinks: [{
            id: 'tradesQueueList',
            name: 'Trade Queue',
            routeUrl: '/eclipse/tradeorder/queue'
          }, {
            id: 'tradesQueueHistory',
            name: 'Queue History',
            routeUrl: '/eclipse/tradeorder/queuehistory'
          }]
        }, {
          id: 'tradesDeleted',
          name: 'Deleted',
          routeUrl: '/eclipse/tradeorder/deleted'
        }, {
          id: 'tradesAstroBatches',
          name: 'Astro Batches',
          hidden: !astroPrivilege,
          routeUrl: '/eclipse/tradeorder/batches'
        }
      ]
    });
  }

  private getTradeNavigationChildLink(isTradeCountsPolled: boolean, tradesCountName: string, notificationType: string): Observable<any> {
    let childLink: Observable<any>;
    if (isTradeCountsPolled) {
      childLink = this._tradeService.tradesCountUpdated.pipe(map((tradesCount: ITradesCountUpdate) => tradesCount[tradesCountName]));
    } else {
      childLink = this.notifications$.pipe(filter(msg => msg.typeId === notificationType), map(msg => this.createCounterDelta(msg)));
    }
    return childLink;
  }

  private createStrategyNavigationItems(): Observable<ILink> {
    const restrictedPlan = this.sessionHelper.getPermission(Consts.PRIV_RESTRICTEDPLANS);
    const hasRestrictedPlan = !!restrictedPlan?.canRead;
    const securitySetsPrivilege = this.sessionHelper.getPermission(Consts.PRIV_SECURITYSETS);
    const hasSecuritySetsAccess = securitySetsPrivilege && (securitySetsPrivilege.canAdd || securitySetsPrivilege.canDelete || securitySetsPrivilege.canRead || securitySetsPrivilege.canUpdate);
    const sleeveAccess = this.sessionHelper.getPermission(Consts.PRIV_SLEEVES);
    const canReadSleeves = !!sleeveAccess?.canRead;
    const modelAccess = this.sessionHelper.getPermission(Consts.PRIV_MODELS);
    const canReadModel = !!modelAccess?.canRead;

    const links = <ILink>{
      id: 'strategies',
      externalUrl: null,
      name: 'Strategies',
      type: 'Route',
      imageName: 'fas fa-fw fa-flip-vertical fa-code-branch',
      routeUrl: '/eclipse/strategy/model/list',
      childLinks: []
    };

    if(canReadModel){
      links.childLinks.push({
        id: 'models',
        name: 'Models',
        routeUrl: '/eclipse/strategy/model/list',
        alternateLinks: ['/eclipse/strategy/model/detail', '/eclipse/strategy/model/edit',
          '/eclipse/strategy/model/view',
          '/eclipse/strategy/model/viewstructure', '/eclipse/strategy/model/modelinfoadd',
          '/eclipse/strategy/model/audit']
      });
    }
    if (hasSecuritySetsAccess) {
      links.childLinks.push({
        id: 'security-sets',
        name: 'Security Sets',
        routeUrl: '/eclipse/strategy/securityset/list',
        alternateLinks: ['/eclipse/strategy/securityset']
      });
    }

    if (canReadSleeves) {
      links.childLinks.push({
          id: 'sleeve-strategy-list',
          name: 'Sleeve Strategies',
          routeUrl: '/eclipse/strategy/sleevestrategy/list'
        },
        {
          id: 'sleeve-strategy-agg-list',
          name: 'Sleeve Strategy Aggregates',
          routeUrl: '/eclipse/strategy/sleevestrategyaggregate/list'
        });
    }

    if (hasRestrictedPlan) {
      links.childLinks.push({
        id: 'restricted-plan-list',
        name: 'Restricted Plans',
        routeUrl: '/eclipse/strategy/restrictedplan/list',
        alternateLinks: [
          '/eclipse/strategy/restrictedplan/add',
          '/eclipse/strategy/restrictedplan/edit',
          '/eclipse/strategy/restrictedplan/view',
          '/eclipse/strategy/restrictedplan/copy'
        ]
      });
    }

    // If the user doesn't have access to any of the Strategies components, don't show the Strategies link
    return of(links.childLinks.length ? links : null);
  }

  private createAdminNavigationItems(): Observable<ILink> {
    const roleType = this.sessionHelper.get<IRole>('role').roleType;
    const custodian = Util.getPermission(Consts.PRIV_CUSTODIANS);
    const hasCustodian = custodian && (custodian.canAdd || custodian.canDelete || custodian.canRead || custodian.canUpdate);
    const preferences = Util.getPrefsPermission();
    const hasPreferences = preferences && (preferences.canAdd || preferences.canDelete || preferences.canRead || preferences.canUpdate);
    const securityPrivilege = Util.getPermission(Consts.PRIV_SECURITIES);
    const hasSecurityAccess = securityPrivilege && (securityPrivilege.canAdd || securityPrivilege.canDelete || securityPrivilege.canRead || securityPrivilege.canUpdate);
    const custodianName = 'Custodians & Brokers';

    const links = {
      id: 'admin',
      externalUrl: null,
      name: 'Administration',
      type: 'Route',
      imageName: 'fas fa-fw fa-building',
      ignoreLink: true,
      childLinks: []
    } as ILink;

    if (hasCustodian) {
      links.childLinks.push({
        id: 'adminCustodian',
        name: custodianName,
        routeUrl: '/eclipse/admin/custodian'
      });
    }

    if (hasPreferences) {
      links.childLinks.push({
        id: 'adminPreferences',
        name: 'Preferences',
        childLinks: [
          {
            id: 'adminPreferencesFirm',
            name: 'Firm',
            hidden: !this._preferenceHelper.hasFirmPermission(roleType),
            routeUrl: '/eclipse/admin/preferences/firm',
          }, {
            id: 'adminPreferencesCustodian',
            name: custodianName,
            hidden: !this._preferenceHelper.hasCustodianPermission(roleType),
            routeUrl: '/eclipse/admin/preferences/custodian'
          }, {
            id: 'adminPreferencesTeam',
            name: 'Team', //
            hidden: !this._preferenceHelper.hasTeamPermission(roleType),
            routeUrl: '/eclipse/admin/preferences/team'
          }, {
            id: 'adminPreferencesModel',
            name: 'Model',
            hidden: !this._preferenceHelper.hasModelPermission(roleType),
            routeUrl: '/eclipse/admin/preferences/model'
          }, {
            id: 'adminPreferencesPortfolio',
            name: 'Portfolio',
            hidden: !this._preferenceHelper.hasPortfolioPermission(roleType),
            routeUrl: '/eclipse/admin/preferences/portfolio'
          }, {
            id: 'adminPreferencesAccount',
            name: 'Account',
            hidden: !this._preferenceHelper.hasAccountPermission(roleType),
            routeUrl: '/eclipse/admin/preferences/account'
          },
        ]
      });
    }

    if (hasSecurityAccess) {
      links.childLinks.push(...[{
        id: 'securitiesList',
        name: 'Securities',
        routeUrl: '/eclipse/security/maintenance/list',
        alternateLinks: ['/eclipse/security/maintenance', '/eclipse/security/maintenance/edit', '/eclipse/security/maintenance/view', '/eclipse/security/audit']
      },
        {
          id: 'securitiesAssets',
          name: 'Assets',
          childLinks: [
            {
              id: 'securitiesAssetsCategory',
              name: 'Category Maintenance',
              routeUrl: '/eclipse/security/asset/category'
            }, {
              id: 'securitiesAssetsClass',
              name: 'Class Maintenance',
              routeUrl: '/eclipse/security/asset/class'
            }, {
              id: 'securitiesAssetsSubClass',
              name: 'Sub Class Maintenance',
              routeUrl: '/eclipse/security/asset/subclass'
            }
          ]
        }]);
    }

    // replace the link for the Preferences link with the first child link available.
    const preferencesLink = links.childLinks.find(l => l.name === 'Preferences' && !l.hidden);
    if (preferencesLink) {
      const prefLevelLinks = preferencesLink.childLinks.filter(l => !l.hidden);
      if (prefLevelLinks.length) {
        preferencesLink.routeUrl = prefLevelLinks[0].routeUrl;
      }
    }

    return of(links);
  }

  private createManageUsersNavigationItems(): Observable<ILink> {
    const user = Util.getPermission(Consts.PRIV_USERS);
    const hasUser = user && (user.canAdd || user.canDelete || user.canRead || user.canUpdate);
    const role = Util.getPermission(Consts.PRIV_ROLES);
    const hasRole = role && (role.canAdd || role.canDelete || role.canRead || role.canUpdate);
    const team = Util.getPermission(Consts.PRIV_TEAMS);
    const hasTeam = team && (team.canAdd || team.canDelete || team.canRead || team.canUpdate);

    const links = {
      id: 'manageUsers',
      externalUrl: null,
      name: 'Manage Users',
      type: 'Route',
      imageName: 'fas fa-fw fa-users-cog',
      routeUrl: '/eclipse/manageusers',
      ignoreLink: true,
      childLinks: []
    } as ILink;

    if (hasUser) {
      links.childLinks.push({
        id: 'user',
        name: 'Users',
        routeUrl: '/eclipse/manageusers/user'
      });
    }

    if (hasTeam) {
      links.childLinks.push({
        id: 'teams',
        name: 'Teams',
        routeUrl: '/eclipse/manageusers/team'
      });
    }

    if (hasRole) {
      links.childLinks.push({
        id: 'roles',
        name: 'Roles',
        routeUrl: '/eclipse/manageusers/role'
      });
    }

    return of(links.childLinks.length ? links : null);
  }

  private createQueryBuilderNavigationItems(): Observable<ILink> {
    const queriesPrivilege: IRolePrivilege = Util.getPermission(Consts.PRIV_QUERIES);

    if (!queriesPrivilege || !queriesPrivilege.canRead) {
      return of(null);
    }
    return of({
      id: 'queryBuilder',
      externalUrl: null,
      name: 'Queries',
      type: 'Route',
      imageName: 'fas fa-fw fa-list',
      routeUrl: this._splitIOService.featureFlags['TEXP_querybuilder_primeng_10966'] ? '/eclipse/querybuilder' : '/eclipse/querybuilder/legacy'
    });
  }

  private createCustomImportsNavigationItems(): Observable<ILink> {
    return this._splitIOService.flagEnabled('Eclipse_CustomImports_UIAccess_194800', 'on')
      .pipe(map(result => this.getCustomImportNavigationLink(result)));
  }

  private getCustomImportNavigationLink(customImportsFeatureFlagEnabled: boolean): ILink {
    const customImportsPrivilege: IRolePrivilege = Util.getPermission(Consts.PRIV_CUSTOMIMPORTS);

    if (!customImportsFeatureFlagEnabled || !customImportsPrivilege || !customImportsPrivilege.canRead){
      return null;
    }
    return {
      id: 'customImports',
      externalUrl: null,
      name: 'Custom Imports',
      type: 'Route',
      imageName: 'fas fa-fw fa-circle-arrow-up',
      routeUrl: '/eclipse/customimports'
    };
  }

  private createCommunitiesNavigationItems(): Observable<ILink> {
    const communityPrivilege = Util.getPermission(Consts.PRIV_COMMUNITYPORTAL);
    if (!communityPrivilege || !communityPrivilege.canRead) {
      return of(null);
    }

    const eclipseToken = this.sessionHelper.getAccessToken('accessTokenInfo');
    const communityPortalNavigation = `${ConfigService.settings.communitiesUrl}${eclipseToken.orion_access_token}`;

    return of({
      id: 'communities',
      externalUrl: communityPortalNavigation,
      type: 'External',
      name: 'Communities',
      imageName: 'communities-icon fa-fw sidebar-image-icon',
      routeUrl: null,
    });
  }

  private createOCNavigationItems(): Observable<ILink> {
    const eclipseToken = this.sessionHelper.getAccessToken('accessTokenInfo');
    const ocUrl = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/sso.html?t=${eclipseToken.orion_access_token}`;

    return of({
      id: 'orionConnect',
      externalUrl: ocUrl,
      type: 'External',
      name: 'Orion Connect',
      imageName: 'orion-connect-icon fas fa-fw sidebar-image-icon',
      routeUrl: null,
    });
  }
}
