import { AfterViewInit, Component, ViewChild, ElementRef, NgZone, Inject, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from './core/base.component';
import { IUser } from './models/user';
import { ILogin } from './login/login';
import { IFirmProfile } from './models/firm';
import { ILoginAs } from './models/loginas';
import { LoginAsService } from './services/loginas.service';
import { UserService } from './services/user.service';
import { DashboardService } from './services/dashboard.service';
import { GlobalSearchService } from './services/global-search.service';
import { NotificationService } from './services/notification.service';
import { FirmService } from './services/firm.service';
import { IRole } from './models/role';
import { IGlobalSearchResults } from './models/globalsearch';
import { NotificationComponent } from './shared/notification/notification.component';
import { ICustomUser } from './models/user';
import { Subscription, forkJoin, Observable, of, Subject, filter, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService, SessionHelper } from './core';
import { AutoComplete } from 'primeng/autocomplete';
import { VersionCheckService } from './core/version-check.service';
import { IAdvisorInfo, IChatTeams } from './viewModels/chatTeams';
import * as _ from 'lodash';
import { ConfigService } from './config/config.service';
import { WINDOW } from './providers/window.provider';
import { UserType } from './libs/app.constants';
import { SplitIoService } from './core/feature-flag/splitio.service';
import { DatadogService } from './services/datadog.service';
import { Theme, ThemeService } from './core/theme/theme.service';
import { EntityEditorService } from './shared/entity-editor/entity-editor.service';
import { IIdName } from './models/tom';
import { AnalyticsService } from './services/analytics.service';
import { IAnalyticsBannerSpinnerStatus } from './models/analytics';
import { INotificationVM } from './models/notification';
import { AnalyticsHelper } from './shared/analytics-duration/analytics-helper';
import { Utils as Util } from "./core/functions";
import * as Consts from './libs/app.constants';
declare let sm: any;
declare let $: any;

@Component({
  templateUrl: './eclipse.component.html'
})
export class EclipseComponent extends BaseComponent implements AfterViewInit {
  connection;
  role: IRole;
  user: IUser;
  firmProfile: IFirmProfile = <IFirmProfile>{};
  displayFirmProfile: boolean = false;
  displayName: string;
  @ViewChild('notifications') notificationComponent: NotificationComponent;
  @ViewChild('autoCompleteLoginAsSearchObject') autoCompleteLoginAsSearchObject: AutoComplete;
  @ViewChild('autoCompleteFirmSearchObject') autoCompleteFirmSearchObject: AutoComplete;
  private listSubscription: Subscription;
  selectedSearchResult: string;
  searchSuggestions: IGlobalSearchResults[] = [];
  searchResults: IGlobalSearchResults[] = [];
  showSearchModuleOptions: boolean = false;
  showSearchOptionsFlag: boolean = false;
  userSuggestions: any[] = [];
  chatTeams: IChatTeams[] = [];
  selectedUser: IUser;
  showLoginAs: boolean = false;
  showSwitchFirm: boolean = false;
  displayLogo: boolean = false;
  serviceTeamInfo: any;
  advisorInfo: IAdvisorInfo;
  files: any[] = [];
  selectedFile: File;
  imagePath: any;
  firmLogo: any; // the current firm logo (path or image bytes)
  newFirmLogo: any; // the temporary firm logo uploaded by the user (image bytes)
  public validMimeTypes = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif', 'image/svg+xml'];  //Acceptable MIME types for image uploads.
  checkUploadFile: boolean = true;
  disableUploadBtn: boolean = true;
  checkDragFile: boolean = false;
  profileImage: any;
  fileUploadError: string;
  showFileUploadError: boolean = false;
  dragFileName: string;
  isProfileImage: boolean = false;
  userProfile: IUser = <IUser>{};
  displayUserProfile: boolean;
  displayLoginAs: boolean = false;
  loginAsInfo: ILoginAs = <ILoginAs>{};
  token: ILogin;
  loginAsErrorMessage: string;
  showNoMergedOCFirmErrMsg: boolean = false;
  searchText: string;
  // Global Search Options Checkboxes
  @ViewChild('searchOptionsDropdown') searchOptionsDropdown;
  selectAll: boolean = true;
  selectPortfolios: boolean = true;
  selectAccounts: boolean = true;
  selectModels: boolean = true;
  selectTrades: boolean = true;
  selectSecurities: boolean = true;
  selectAdministrator: boolean = true;
  selectDataQuery: boolean = true;
  selectRebalancer: boolean = true;
  selectedOptions: string[] = [];
  isShowSpinner: boolean;
  durationConnection;
  needAnalyticsCountConnection;
  isReset: boolean;
  private editUser: ICustomUser = <ICustomUser>{};
  msgCount = 0;
  analyticCount = 0;
  noticount = 0;
  displaySwitchFirm: boolean = false;
  firmsList: IFirmProfile[] = [];
  firmsSuggestions: IFirmProfile[] = [];
  switchFirmId: number;
  runningLocal: boolean = false;
  displayContactUs: boolean = false;
  switchFirmName: string;
  switchFirmObj: any;
  switchFirmErrorMsg: string = '';
  disableSwitchButton: boolean = true;
  notificationCount = 0;
  alerts: any[];
  firmId: number = 0;
  firmName: string = 'FIRM NAME';
  firmTypes: IIdName[] = [];
  orionSupportUrl: string;
  public themes = Theme;
  private destroyed$: Subject<void> = new Subject<void>();
  public sidebarCollapsed: boolean;
  analyticsPortfolioStatusSubscription: Subscription;
  analyticsBannerSpinnerInterval: number;
  checkPermissionForResetAnalytics: boolean = false;
  displayResetAnalytics: boolean = false;

  constructor(private _router: Router, private element: ElementRef, private sessionHelper: SessionHelper,
              private _firmService: FirmService, private _userService: UserService, private _dashboardservice: DashboardService,
              private _globalSearchService: GlobalSearchService, private _loginAsService: LoginAsService, private _lc: NgZone,
              private _authService: AuthService, private notificationService: NotificationService, public versionChecker: VersionCheckService,
              private readonly _splitService: SplitIoService, private readonly _versionCheckService: VersionCheckService,
              private readonly _datadogService: DatadogService, @Inject(WINDOW) private readonly window: Window, public readonly themeService: ThemeService,
              private readonly _entityEditorService: EntityEditorService, private readonly _viewContainerRef: ViewContainerRef,
              private readonly _analyticsService: AnalyticsService, private readonly _configService: ConfigService,
              private readonly _splitIoService: SplitIoService) {
    super('', sessionHelper);
    _entityEditorService.viewContainerRef = _viewContainerRef;
    this.firmProfile = <IFirmProfile>{};
    document.addEventListener('click', this.offClickHandler.bind(this));

    const user = this.sessionHelper.getUser();

    this.firmId = user.firmId;
    this.firmName = user.firmName;

    // Log page views with Google Analytics
    if (environment.production) {
      this._router.events.pipe(
        takeUntil(this.destroyed$),
      ).subscribe(event => {
        if ((<any>window).ga && event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
    }

    this.checkPermissionForResetAnalytics = !!this._splitIoService.featureFlags['TEXP_hide_reset_analytics_9763'];
    const resetAnalyticsPermission = Util.getPermission(Consts.PRIV_RESETANALYTICS);
    this.displayResetAnalytics = !!resetAnalyticsPermission?.canUpdate || !this.checkPermissionForResetAnalytics;
  }

  ngOnInit() {
    if (!!$) {
      $.material.init();
    }
    // this will not load walkMe for local or any dev environments
    this.runningLocal = this._configService.isLocal();
    this.loadLayout();
    this.subscribeAnalyticsPortfolioStatusNotification();
    this.subscribeNeedAnalyticsCount();
    this.walkMeInit();
    this.loadFullStoryScript();
    this.getServiceTeamInfo()
      .subscribe(() => {
        setTimeout(() => {
          this._lc.run(() => {
            this.initializeDataDog();
          });
        }, 0);
      });
    this.orionSupportUrl = ConfigService.settings.orionSupportUrl;
    this.getAnalyticsBannerSpinnerStatus();
    this.getAnalyticsBannerSpinnerStatusInEveryFiveMinutes();
  }

  initializeDataDog(): void {
    this._splitService.flagsEnabled(['eclipse_ui_datadog', 'eclipse_ui_datadog_on_app_start_210974'])
      .subscribe((flags) => {
        if(!!flags['eclipse_ui_datadog'] && !flags['eclipse_ui_datadog_on_app_start_210974']) {
          // Once the app version has been determined, initialize Datadog
          this._versionCheckService.currentVersion$
            .pipe(
              filter(appVersion => !!appVersion?.timestamp), // ignore null app versions
              take(1) // initialize after the first valid app version is seen
            ) // only take the first version
            .subscribe(() => {
              this._datadogService.initialize();
            });
        }
      });
  }

  private getAnalyticsBannerSpinnerStatus(): void {
    this._analyticsService.getAnalyticsBannerSpinnerStatus()
      .subscribe((result: IAnalyticsBannerSpinnerStatus) => {
        this.isShowSpinner = result.isShowSpinner;
      });
  }

  loadFullStoryScript(): void {
    if (this.runningLocal) {
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = './assets/js/fullStory.js';
    script.onerror = (error: any) => console.error('Failed to load FullStory script.', error);
    script.onload = () => {
      (<any>window).FS.identify(this.user.userLoginId, {
        displayName: this.user.name,
        email: this.user.email,
        firmName_str: this.user.firmName,
        firmId_int: this.user.firmId,
        loginLevel_str: this.user.role.roleType
      });
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  walkMeInit() {
    this.window['userId'] = this.user.userLoginId;
    this.window['alClientName'] = this.user.firmName;
    this.window['databaseUser'] = `${this.user.firmName} - ${this.user.userLoginId}`;
    this.window['userLevel'] = this.user.role.roleType;
    this.window['walkme_load_in_iframe'] = true;

    if (this.runningLocal) {
      return;
    }
    const urlPart = this.window.location.host.includes('alpha') || this.window.location.host.includes('alpha2') || this.window.location.host.includes('qa') ? '/test' : '';

    const walkme = document.createElement('script');
    walkme.type = 'text/javascript';
    walkme.async = true;
    walkme.src = `https://d3b3ehuo35wzeh.cloudfront.net/users/f697c209a5c447f585225b254e394973${urlPart}/walkme_f697c209a5c447f585225b254e394973_https.js`;

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(walkme, s);
  }

  updateSidebar(state: boolean) {
    this.sidebarCollapsed = state;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.needAnalyticsCountConnection.unsubscribe();
    this.analyticsPortfolioStatusSubscription.unsubscribe();
  }

  /** Load layout screen data at component initialization time */
  loadLayout() {
    this.user = this._sessionHelper.getUser();
    this.setUserProfile();
    this.role = this._sessionHelper.getUser().role;

    this.getFirmProfile();

    if (this.isFirmAdmin || this.isOrionAdmin) {
      /** To show/hide no merged firms popup and message*/
      const mergedFirmsDetails = this._sessionHelper.get('mergedFirmsDetails');
      if (!mergedFirmsDetails) {
        if (this.user.ocFirmId !== null) {
          this.showNoMergedOCFirmErrMsg = false;
        } else {
          this.showNoMergedOCFirmErrMsg = true;
          this._sessionHelper.set('mergedFirmsDetails', {showPopup: true, showLayoutMessage: true});
        }
      } else {
        this.showNoMergedOCFirmErrMsg = mergedFirmsDetails['showLayoutMessage'];
      }
      this.getNoOrionConnectFirmErrMsgNotification();
    }

    /**Flags to show Login As and Switch Firm Options */
    const canLoginAs = this.role.privileges.filter(elem => elem.code === 'LOGINAS');
    if (canLoginAs && canLoginAs.length) {
      this.showLoginAs = canLoginAs[0].canRead;
    }
    const canSwitchFirm = this.role.privileges.filter(elem => elem.code === 'SWITCHFIRM');
    if (canSwitchFirm && canSwitchFirm.length) {
      this.showSwitchFirm = canSwitchFirm[0].canRead;
    }
  }

  /** AutoComplete Search All Modules by name or id */
  loadSearchSuggestions(event) {
    this.showSearchModuleOptions = false;
    event.query = event.query.trim();
    this.searchText = event.query;
    if (event.query !== '') {
      this.selectedOptions = [];
      if (this.selectAll) {
        this.selectedOptions.push('All');
      }
      if (this.selectPortfolios) {
        this.selectedOptions.push('Portfolios');
      }
      if (this.selectAccounts) {
        this.selectedOptions.push('Accounts');
      }
      if (this.selectModels) {
        this.selectedOptions.push('Models');
      }
      if (this.selectTrades) {
        this.selectedOptions.push('Trades');
      }
      if (this.selectSecurities) {
        this.selectedOptions.push('Securities');
      }
      if (this.selectAdministrator) {
        this.selectedOptions.push('Administrator');
      }
      if (this.selectDataQuery) {
        this.selectedOptions.push('DataQuery');
      }

      this._globalSearchService.getGlobalDataSearchResults(event.query.toLowerCase(), this.selectedOptions)
        .subscribe((data) => {
          this.searchSuggestions = [...data];
        });
    } else {
      this.searchSuggestions = [];
    }
  }

  offClickHandler(event: any) {
    if (!this.searchOptionsDropdown.nativeElement.contains(event.target) && !this.showSearchOptionsFlag) { // check click origin
      this.showSearchModuleOptions = false;
    }

    this.showSearchOptionsFlag = false;
  }

  toggleAll() {
    this.selectPortfolios = this.selectAll;
    this.selectAccounts = this.selectAll;
    this.selectModels = this.selectAll;
    this.selectTrades = this.selectAll;
    this.selectSecurities = this.selectAll;
    this.selectAdministrator = this.selectAll;
    this.selectDataQuery = this.selectAll;
  }

  toggleSearchOptions(option, $event) {
    if (!$event.target.checked) {
      this.selectAll = false;
    } else {
      const selectPortfoliosOption = this.selectPortfolios || (option === 'Portfolios');
      const selectAccountsOption = this.selectAccounts || (option === 'Accounts');
      const selectModelsOption = this.selectModels || (option === 'Models');
      const selectTradesOption = this.selectTrades || (option === 'Trades');
      const selectSecuritiesOption = this.selectSecurities || (option === 'Securities');
      const selectAdministratorOption = this.selectAdministrator || (option === 'Administrator');
      const selectDataQueryOption = this.selectDataQuery || (option === 'Data Query');

      if (selectPortfoliosOption && selectAccountsOption &&
        selectModelsOption && selectTradesOption && selectSecuritiesOption && selectAdministratorOption
        && selectDataQueryOption) {
        this.selectAll = true;
      }
    }
  }

  handleDropdown(event) {
    // event.query = current value in input field
    this.showSearchModuleOptions = !this.showSearchModuleOptions;
    this.showSearchOptionsFlag = this.showSearchModuleOptions;
  }

  /** Fires on select search Result from global search */
  onSearchSelect(params: any) {
    if (!!params?.entityEditorConfig) {
      this._entityEditorService.show(params.entityEditorConfig);
    } else {
      this._router.navigate([params.routeUrl]).then();
    }
    this.selectedSearchResult = '';
  }

  setUserProfile() {
    if (this.user) {
      this.displayName = this.user.name;
      this.userProfile.name = this.user.name;
      this.userProfile.email = this.user.email;
    }
  }

  openChatWindow(chatTeam) {
    const user = this._sessionHelper.get<IUser>('user');
    const advisorNum = this._sessionHelper.get<IAdvisorInfo>('advisorInfo').value;
    (<any>window).sm.getApi({version: 'v1'}).then(function (salemove) {
      salemove.updateInformation({
        externalId: user.email,
        email: user.email,
        name: user.name,
        phone: '',
        customAttributes: {
          advisorNumber: advisorNum,
          advisorName: user.firmName,
          currentAppLocation: 'Eclipse',
          intendedRecipient: chatTeam.chatRouteIntendedRecipient,
          userId: user.orionUserId?.toString(),
        }
      }).then(function () {
        salemove.visitorApp.triggerQueueMediaSelection(chatTeam.queue);
      });
    });
  }

  ngAfterViewInit() {
    this.msgCount = this.notificationComponent?.msgCount || 0;
  }

  getNotificationCount(e) {
    this.msgCount = e;
  }

  showProfilePopup() {
    if(!this.firmTypes?.length) {
      this._firmService.getFirmTypes()
        .subscribe(result => {
          this.firmTypes = result;
        });
    }
    this.displayLogo = true;
    this.displayFirmProfile = true;
  }

  showUserProfile() {
    this.displayUserProfile = true;
    this.user = this._sessionHelper.getUser();
  }

  /** FileUpload Control */

  /** To dragFile */
  dragFile(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /** To drop file */
  dropFile(event) {
    const that = this;
    this.displayLogo = true;
    if (event.dataTransfer.files.length !== 1) {
      this.fileUploadError = 'Only one file can be uploaded at a time.';
      this.showFileUploadError = true;
    } else {
      this.checkDragFile = true;
      this.dragFileName = event.dataTransfer.files[0].name;
      this.selectedFile = event.dataTransfer.files[0];
      // Create a FileReader
      const reader = new FileReader();
      reader.onload = function (e) {
        that.imagePath = e.target.result;
      };

      this.selectedFile = event.dataTransfer.files[0];
      // read the image file as a data URL.
      reader.readAsDataURL(event.dataTransfer.files[0]);
      this.checkUploadFile = false;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  /** To select template */
  onChangeLogo() {
    this.selectedFile = null;
    this.displayLogo = false;
  }

  onCancel() {
    this.displayFirmProfile = this.displayLogo = false;
    this.imagePath = '';
    this.newFirmLogo = null;
    this.selectedFile = null;
    this.getFirmProfile();
  }

  getFirmProfile() {
    this._firmService.getFirmProfile()
      .subscribe((firm: IFirmProfile) => {
        this.firmProfile = firm;
        this.firmName = firm.name;
        if (this.firmProfile.imagePath !== '' || this.firmProfile.imagePath !== null) {
          this.imagePath = this.firmProfile.imagePath;
          this.displayLogo = true;
        }

        // Update the firm name on the user in session
        const user = this.sessionHelper.getUser();
        user.firmName = firm.name;
        this.sessionHelper.set('user', user);
      });

    this.getFirmLogo();
  }

  /**
   * Gets the firm logo file and creates an image that can be bound to an img element.
   * If no logo is found, or an error occurs while loading it, default to the Orion logo.
   */
  getFirmLogo() {
    this._firmService.getFirmLogo()
      .subscribe({
        next: (logo) => {
          try {
            if (!!logo) {
              this.createImageFromBlob(logo);
              this.displayLogo = true;
              return;
            }
          } catch (ex) {
            console.warn('Error loading firm logo', ex);
          }
          this.setDefaultFirmLogo();
        }
      });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.firmLogo = reader.result;
    }, false);
    reader.readAsDataURL(image);
  }

  setDefaultFirmLogo() {
    this.firmLogo = 'assets/img-dark/orion-logo.png';
  }

  saveProfile() {
    // Update the firm logo only if a new logo has been selected
    let firmLogoUpdate: Observable<any> = of(true);
    if (this.selectedFile) {
      firmLogoUpdate = this._firmService.updateFirmLogo(this.selectedFile);
    }
    firmLogoUpdate
      .pipe(
        switchMap(() => this._firmService.updateFirmProfile(null, this.firmProfile)),
        tap(() => {
          this.getFirmProfile();
          this.firmLogo = this.newFirmLogo ?? this.firmLogo;
          this.newFirmLogo = null;
          this.displayFirmProfile = false;
        })
      ).subscribe();
  }

  saveUserProfile() {
    if (!this.isValid()) {
      this.showFileUploadError = true;
      this.fileUploadError = 'No fields changed to update the profile.';
      this.displayUserProfile = true;
    }
    if (this.userProfile.userLogo !== undefined && this.profileImage) {
      this._userService.updateUserProfileImage(this.profileImage)
        .subscribe(usr => {
            this.user.userLogo = usr.imagePath;
            this.isProfileImage = false;
            this.displayUserProfile = false;
          },
          error => (console.log(`Update Failed: ${JSON.stringify(error)}`)));
    }
    if (this.userProfile.name !== this.user.name || this.userProfile.email !== this.user.email) {
      this.editUser.teamIds = [];
      this.user.teams.forEach(element => {
        this.editUser.teamIds.push(element.id);
      });
      this.editUser.name = this.user.name;
      this.editUser.email = this.user.email;
      this.editUser.startDate = this.formatDate(this.user.startDate);
      this.editUser.expireDate = this.formatDate(this.user.expireDate);
      this.editUser.id = this.user.id;
      this.editUser.userLoginId = this.user.userLoginId;

      if (this.user.role) {
        this.editUser.roleId = this.user.role.id;
      } else {
        this.editUser.roleId = 0;
      }

      this.editUser.status = this.user.status;

      return this._userService.updateUser(this.user.id, this.editUser)
        .subscribe(() => {
            this.displayUserProfile = false;
            this.getUserData();
            // Update email in notification preferences tab
            if (this.notificationComponent) {
              this.notificationComponent.userEmail = this.user.email;
            }
          },
          error => (console.log(`Update Failed: ${JSON.stringify(error)}`)));
    }
  }

  selectUserProfileImage(event) {
    this.isProfileImage = false;
    this.profileImage = event.target.files[0];
    if (!this.isValidImageFormat(this.profileImage.type)) {
      {
        this.showFileUploadError = true;
        this.fileUploadError = 'Upload only image.';
        this.displayUserProfile = true;
      }
    } else {
      this.isProfileImage = true;
      this.userProfile.userLogo = event.target.files[0].name;
      this.showFileUploadError = false;
    }
  }

  selectFirmProfileImage(event) {
    const that = this;
    this.displayLogo = true;
    this.selectedFile = event.target.files[0];
    if (!this.isValidImageFormat(this.selectedFile.type)) {
      this.showFileUploadError = true;
      this.displayLogo = false;
      this.fileUploadError = 'Invalid image.  Please upload only a JPEG, PNG, BMP, GIF, or SVG image type.';
      this.displayFirmProfile = true;
    } else {
      const reader = new FileReader();
      reader.onload = function (evt) {
        that.imagePath = evt.target.result;
        that.newFirmLogo = evt.target.result;
      };
      // read the image file as a data URL.
      this.selectedFile = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.checkUploadFile = false;
      this.showFileUploadError = false;
      this.displayLogo = true;
    }
  }

  isValidImageFormat(fileType: string) {
    return this.validMimeTypes.includes(fileType);
  }

  closeUserModal() {
    this.showFileUploadError = false;
    this.isProfileImage = false;
    this.profileImage = undefined;
    this.userProfile.userLogo = '';
    this.displayUserProfile = false;
  }

  dragProfileImage(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  dropProfileImage(event) {
    this.profileImage = event.dataTransfer.files[0];

    if (event.dataTransfer.files.length !== 1) {
      this.fileUploadError = 'Only one image can be uploaded.';
      this.showFileUploadError = true;
      this.displayUserProfile = true;
    } else if (!this.isValidImageFormat(this.profileImage.type)) {
      this.fileUploadError = 'Upload Only Image.';
      this.showFileUploadError = true;
      this.displayUserProfile = true;
    } else {
      this.isProfileImage = true;
      this.userProfile.userLogo = this.profileImage.name;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  isValid() {
    return !(this.user.name === this.userProfile.name && this.user.email === this.userProfile.email
      && this.profileImage === undefined);
  }

  getUserData() {
    /** load user data */
    this._userService.getUser()
      .subscribe((user: IUser) => {
          if (user) {
            this.displayName = this.user.name;
          }
          if (user.role) {
            this._authService.currentUser = user;
            this.user = this._sessionHelper.getUser();
            this.setUserProfile();
          }
        },
        error => {
          console.log(`error occured while fetching user details. ${JSON.stringify(error)}`);
        });
  }

  /** Start of LoginAs Code */

  /**Open login as popup */
  openLoginAsModal() {
    this.displayLoginAs = true;
    this.loginAsErrorMessage = '';
    this.selectedUser = null;
    this.loginAsInfo = <ILoginAs>{};
  }

  /** Login as : Get token as response, and update this with existed one */
  loginAs() {
    this.loginAsInfo.firmId = this.user.firmId;
    this._loginAsService.loginAs(this.loginAsInfo)
      .subscribe((model: ILogin) => {
          this.setTokenInfo(model);
          /** setting token info */
          this.updateUserInfo();     /** setting user information */
        },
        error => {
          this.loginAsErrorMessage = error.message; /**showing login as error message */
        });
  }

  /** Close loginas popup */
  closeLoginAsModal() {
    this.loginAsErrorMessage = '';
    this.selectedUser = null;
    this.loginAsInfo = <ILoginAs>{};
    this.displayLoginAs = false;
  }

  /** Set token Information */
  setTokenInfo(tokenInfo: ILogin) {
    this.token = tokenInfo;
    this.token.isAuthenticated = true;
    this._authService.setToken(tokenInfo);
  }

  /**Method to show Switch Firm Popup */
  showFirmList() {
    // don't show the firm list if logged in as someone else
    if (this.user.actualUserId !== undefined && this.user.actualUserId !== null) {
      return;
    }
    this.switchFirmId = undefined;
    this.switchFirmName = undefined;
    this.switchFirmObj = undefined;
    this.switchFirmErrorMsg = '';
    this.firmsList = [];
    this.displaySwitchFirm = true;
    this.disableSwitchButton = true;
  }

  /** Get List of Firms and filter them based on user search query. */
  getFirmList(event) {
    this.disableSwitchButton = true;

    if (this.listSubscription && !this.listSubscription.closed) {
      this.listSubscription.unsubscribe();
    }

    this.listSubscription = this._loginAsService.getSuperAdminFirmList()
      .subscribe((data: IFirmProfile[]) => {
          this.firmsSuggestions = data.filter(x => x.id !== +this.user.firmId
            && (x.id.toString().toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) !== -1 || x.name.toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) !== -1));
        },
        error => {
          this.switchFirmErrorMsg = error.message;
        });
  }

  /**switch between firms associated */
  switchFirm() {
    this._loginAsService.switchFirm(+this.switchFirmId)
      .subscribe((model: ILogin) => {
          // If we get token data, reload details with selected firm
          this.setTokenInfo(model);  /** setting token info */
          this._sessionHelper.removeItem('new-dashboard'); // remove the new-dashboard flag as it may change when switching firms.
          this.updateUserInfo();     /** setting user information */
          this.displaySwitchFirm = false;
        },
        error => {
          this.switchFirmErrorMsg = error.message;
        });
  }

  /** load user data */
  updateUserInfo() {
    this._sessionHelper.removeItem('mergedFirmsDetails'); // removing merged firm details from the session; it was set by depending on the user details only.
    this._userService.getUser()
      .subscribe((user: IUser) => {
          if (user.role && user.role.roleTypeId !== UserType.APIOnly) {
            this._authService.currentUser = user;
            this.closeLoginAsModal();
            this._lc.run(() => {
              this._router.navigate(['loginAs']);
            });
          } else {
            this._sessionHelper.set('isApiOnly', true);
            this._router.navigate(['/']);
          }
        },
        error => {
          console.log(error);
        });
  }

  /** close switch firm popup */
  closeSwitchFirm() {
    this.switchFirmErrorMsg = '';
    this.disableSwitchButton = true;
    this.displaySwitchFirm = false;
  }

  /** revert login as */
  revertLoginAs() {
    this._loginAsService.revertLoginAs()
      .subscribe((model: ILogin) => {
      this.setTokenInfo(model);  /** setting token info */
      this.updateUserInfo();     /** setting user information */
    });
  }

  /**End of LoginAs Code */

  /**Method to get selectd value and enable/disable button */
  firmChange(val) {
    if (val === undefined || val === 'undefined') {
      this.switchFirmId = undefined;
      this.switchFirmName = undefined;
      this.switchFirmObj = undefined;
      this.disableSwitchButton = true;
    } else {
      this.switchFirmId = +val;
      this.switchFirmName = this.firmsSuggestions.find(firm => firm.id === this.switchFirmId).name;
      this.switchFirmObj = this.firmsSuggestions.find(firm => firm.id === this.switchFirmId);
      this.disableSwitchButton = false;
    }
  }

  onAfterShowSwitchFirm(event) {
    this.autoCompleteFirmSearchObject.focusInput();
  }

  onAfterShowLoginAs(event) {
    this.autoCompleteLoginAsSearchObject.focusInput();
  }

  /** AutoComplete Search by UserName/Login Name/User Id - LoginAs*/
  loadUserSuggestions(event) {
    this.loginAsErrorMessage = '';
    this._userService.searchUser(event.query)
      .pipe(map((u: IUser[]) => {
        return u.filter(user => user.role.roleTypeId !== UserType.APIOnly); // remove API Only users
      }))
      .subscribe((users: IUser[]) => {
        this.userSuggestions = users;
      });
  }

  /** To get selected user -LoginAs */
  onUserSelect(params: any) {
    if (params.id !== null && params.id !== undefined && params.id !== '') {
      this.loginAsInfo.userId = params.id;
      this.selectedUser = params;
    }
  }

  getNoOrionConnectFirmErrMsgNotification() {
    this.notificationService.getNoMergeOCFirmErrMsgValue.subscribe(value => {
      this.showNoMergedOCFirmErrMsg = value;
      this._sessionHelper.removeItem('mergedFirmsDetails');
    });
  }

  runNeedAnalytics() {
    this._dashboardservice.runNeedAnalytics().subscribe();
  }

  resetAnalytics(): void {
    this._dashboardservice.resetAnalytics()
      .subscribe(() => {
        this.getResetAnalyticsStatus();
        this.resetAnalyticsRunHistory();
      });
  }

  private resetAnalyticsRunHistory(): void {
    this._analyticsService.resetAnalyticsRunHistory()
      .subscribe();
  }

  getResetAnalyticsStatus() {
    this._dashboardservice.resetAnalyticsStatus().subscribe();
  }

  private subscribeAnalyticsPortfolioStatusNotification(): void {
    this.analyticsPortfolioStatusSubscription = this.notificationService.analyticsPortfolioStatus
      .subscribe((notification: INotificationVM): void => {
        const isShowSpinner = AnalyticsHelper.isShowSpinnerByTrigger(notification?.analyticsNotification?.trigger);
        if (isShowSpinner) {
          this.getAnalyticsBannerSpinnerStatus();
        }
      });
  };

  subscribeNeedAnalyticsCount() {
    this.needAnalyticsCountConnection = this.notificationService.getAnalyticsCount.subscribe(count => {
      this.analyticCount = count;
    });
  }

  showVersionChange(): void {
    this.versionChecker.emitVersionChanged();
  }

  getServiceTeamInfo(): Observable<any> {
    // code from Josh Thoma -- modified to by typescript complaint, sort of
    // -- 7/19/2019 - don't include salemove if running locally
    if (this.runningLocal) {
      return of(null);
    }
    const app = this;
    if (app.user.email !== null && app.user.email !== undefined) {
      const installSaleMove = function (callback) {
        const salemoveIntegrationScriptUrl = 'https://api.glia.com/salemove_integration.js';
        const scriptTagName = 'script';
        const scriptElement = document.createElement(scriptTagName);
        scriptElement.crossOrigin = 'anonymous';
        scriptElement.async = true;
        scriptElement.src = salemoveIntegrationScriptUrl;
        scriptElement.type = 'text/javascript';
        scriptElement.addEventListener('load', callback);
        document.body.append(scriptElement);
      };

      return forkJoin([
        this._userService.getOrionServiceTeam(),
        this._userService.getOrionServiceTeams('ApplicationTeam'),
        this._userService.getOrionServiceTeams('AlternateApplicationTeam'),
        this._userService.getOrionAdvisorNumber()
      ])
      .pipe(tap(([st, sts, ast, ad]) => {
          this.displayContactUs = true;
          this._sessionHelper.set('serviceTeamInfo', st);
          this._sessionHelper.set('advisorInfo', ad);
          const ct = [];
          this.advisorInfo = ad;
          sts.forEach(function (team) {
            if (team.chatUrl) {
              const alternateTeam = ast.find(t => t.team === team.team);
              if (alternateTeam && alternateTeam.chatUrl) {
                Object.assign(team, alternateTeam);
              }
              ct.push({
                team: team.team,
                chatUrl: team.chatUrl,
                chatRouteIntendedRecipient: team.chatRouteIntendedRecipient
              });
            }
          });
          this.chatTeams = ct;
          if (this.chatTeams.length) {
            // Get service team info from session
            const serviceTeam = this._sessionHelper.get<IChatTeams>('serviceTeamInfo');
            const advisorNum = this._sessionHelper.get<IAdvisorInfo>('advisorInfo').value;
            const chatTeams = this.chatTeams;
            const that = this;
            installSaleMove(function () {
              function onQueueState(queue) {
                that._lc.run(() => {
                  if (serviceTeam && (serviceTeam.firmChatRouteId === queue.id || serviceTeam.chatUrl === queue.id)) {
                    serviceTeam.isOpen = (queue.state.status === queue.state.STATUSES.OPEN);
                    serviceTeam['queue'] = queue;
                  } else if (_.some(that.chatTeams, ['chatUrl', queue.id])) {
                    that.chatTeams.find(n => n.chatUrl === queue.id).isOpen = (queue.state.status === queue.state.STATUSES.OPEN);
                    that.chatTeams.find(n => n.chatUrl === queue.id).queue = queue;
                  }
                });
              }

              sm.getApi({version: 'v1'}).then(function (salemove) {
                const queueIds = chatTeams.map(c => c.chatUrl);
                if (serviceTeam.firmChatRouteId) {
                  queueIds.push(String(serviceTeam.firmChatRouteId));
                }
                if (serviceTeam.team === 'Conversion' || serviceTeam.team === 'Implementation') {
                  queueIds.push(serviceTeam.chatUrl);
                }
                salemove.subscribeToQueueStateUpdates(queueIds, onQueueState);
                salemove.updateInformation({
                  externalId: app.user.email,
                  email: app.user.email,
                  name: app.user.name,
                  phone: '',
                  customAttributes: {
                    advisorNumber: advisorNum,
                    advisorName: app.user.firmName,
                    currentAppLocation: 'Eclipse',
                    intendedRecipient: 'Data',
                    userId: app.user.orionUserId?.toString(),
                  }
                });
              });
            });
          }
        })
      );
    }
  }

  notificationsExpanded = false;
  toggleNotificationExpanded(): void {
    this.notificationsExpanded = !this.notificationsExpanded;
  }

  public changeTheme(theme: Theme): void {
    this.themeService.currentTheme = theme;
  }


  /**
   * This method is used to check the spinner status every 5 minutes if the spinner is running.
   */
  private getAnalyticsBannerSpinnerStatusInEveryFiveMinutes(): void {
    clearInterval(this.analyticsBannerSpinnerInterval);
    this.analyticsBannerSpinnerInterval = window.setInterval((): void => {
      if (this.isShowSpinner) {
        this.getAnalyticsBannerSpinnerStatus();
      }
    }, 300000); // Five Minutes
  }

  openOCSupport() {
      const url = `${ConfigService.settings.orionAdvisorEndPoint}api/v1/Integrations/Salesforce/OrionConnect/Action/CommunitySAML?Authorization=${this._authService.getToken().orion_access_token}`;
      this.window.open(url);
  }
}
